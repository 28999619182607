<template>
  <div>
    <b-card no-body>
      <b-card-title class="m-0">
        <div class="commitmentHeader">
          <b-row>
            <b-col
              class="px-3 py-2"
            >
              <div>
                <span
                  class="d-inline commitmentInfoTitle"
                >
                  {{ commitment.client_set.name }}
                </span>
                <b-button
                  id="btn-link-commitment-info"
                  type="button"
                  variant="outline-info"
                  class="mb-1 mb-sm-0 mr-0 p-0"
                  @click="goToBidding()"
                >
                  <svg-icon
                    type="mdi"
                    :path="mdiLaunch"
                    size="18"
                  />
                </b-button>
              </div>
              <div>
                <b-row class="mt-50">
                  <b-col class="d-flex align-items-center">
                    <span class="mr-50 commitmentInfoSubtitleLabel">Nº Pregão</span>
                    <span
                      v-if="commitment.trade_number"
                      class="mr-2 commitmentInfoSubtitleContent"
                    >
                      {{ commitment.trade_number }}
                    </span>
                    <span
                      v-else
                      class="mr-2 commitmentInfoSubtitleContent"
                    >
                      ...
                    </span>
                    <div>
                      <span class="mr-50 commitmentInfoSubtitleLabel">CNPJ</span>
                      <span
                        v-show="commitment.client_set.cnpj"
                        class="commitmentInfoSubtitleContent"
                      >
                        {{ commitment.client_set.cnpj | VMask('##.###.###/####-##') }}
                      </span>
                      <span
                        v-show="!commitment.client_set.cnpj"
                        class="commitmentInfoSubtitleContent"
                      >
                        ...
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              v-if="!isCreateMode"
              class="d-flex justify-content-end"
            >
              <b-button
                class="btn-icon status-btn right-rounded"
                :class="situationButtonClass"
                :disabled="!canUpdate || (isInAudit && !haveAuditPermission) || isDeclined"
                variant="white"
                @click="checkChangeDetections()"
              >
                <svg-icon
                  type="mdi"
                  size="36"
                  class="statusSvg"
                  :style="{ 'color': situationSvgColor }"
                  :path="situationSvgPath"
                />
                <span
                  class="statusText"
                >
                  {{ actionText }}
                </span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-title>
      <b-row
        class="p-2"
      >
        <b-col
          cols="12"
          md="3"
          class="pr-50"
        >
          <company-select
            id="company"
            v-model="commitment.company_set"
            :readonly="true"
            :required-field="true"
            :truncate-field="30"
            @ready="syncLoad.ready('company')"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="px-50"
        >
          <validation-provider
            #default="validationContext"
            name="commitment_number"
            :rules="{
              required: true,
              regex:/^[a-zA-Z0-9\-/]+$/
            }"
          >
            <b-form-group
              label="Nº Empenho"
              label-for="commitment_number"
            >
              <b-form-input
                id="commitment_number"
                v-model="commitment.number"
                :state="readonly ? null : getValidationState(validationContext)"
                name="commitment_number"
                :placeholder="readonly ? '' : 'Nº Empenho'"
                :readonly="readonly || isEditMode"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="px-50"
        >
          <validation-provider
            v-slot="{ errors }"
            name="date_expiration"
            :rules="{
              required: true,
            }"
          >
            <b-form-group
              label="Recebimento do Empenho"
              label-for="date_expiration"
            >
              <b-form-datepicker
                id="id_date_expiration"
                v-model="commitment.date_expiration"
                :required="true"
                locale="pt-BR"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                size="md"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                label-no-date-selected="Nenhuma data foi selecionada"
                selected-variant="primary"
                :disabled="!hasPermissionToWrite || readonly"
                placeholder="Selecione"
                label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                reset-button
                reset-button-variant="info"
                label-reset-button="Limpar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="px-50"
        >
          <validation-provider
            v-slot="{ errors }"
            name="date_delivery"
            :rules="{
              required: true,
            }"
          >
            <b-form-group
              label="Prazo de Entrega"
              label-for="date_delivery"
            >
              <b-form-datepicker
                id="id_date_delivery"
                v-model="commitment.date_delivery"
                :required="true"
                locale="pt-BR"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                size="md"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                label-no-date-selected="Nenhuma data foi selecionada"
                selected-variant="primary"
                :disabled="!hasPermissionToWrite || readonly"
                placeholder="Selecione"
                label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                reset-button
                reset-button-variant="info"
                label-reset-button="Limpar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="pl-50"
        >
          <commitment-status-select
            id="idOrderStatus"
            v-model="commitment.status_set"
            :required-field="true"
            :readonly="readonly"
            @ready="syncLoad.ready('status')"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="idAuditModal"
      ref="refAuditModal"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <template #modal-title>
        Liberar?
      </template>

      <b-row>
        <b-col
          cols="12"
        >
          <div
            class="d-flex align-items-center justify-content-center p-3"
          >
            <h4
              class="m-0 text-center font-weight-bolder"
            >
              Deseja liberar a auditoria do Empenho?
            </h4>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <div
            class="d-flex justify-content-center align-items-center pb-2"
          >
            <b-button
              class="releaseModalBtn minModalBtnWidth"
              @click="checkChangeDetections('idle')"
            >
              Liberar
            </b-button>
            <b-button
              class="declineModalBtn ml-3 minModalBtnWidth"
              @click="checkChangeDetections('declined')"
            >
              Declinar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BCardTitle,
  BCard,
  BButton,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  mdiCheckCircle,
  mdiLaunch,
  mdiNoteSearchOutline,
} from '@mdi/js'

import formValidation from '@core/comp-functions/forms/form-validation'
import SvgIcon from '@jamescoyle/vue-icon'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import CompanySelect from '@/components/company/CompanySelect.vue'
import CommitmentStatusSelect from '@/components/order/CommitmentStatusSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    ValidationProvider,
    CompanySelect,
    CommitmentStatusSelect,
    SvgIcon,
  },
  model: {
    prop: 'commitmentValue',
    event: 'commitmentChange',
  },
  props: {
    commitmentValue: {
      type: [Object, String],
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    canUpdate: {
      type: Boolean,
      required: true,
    },
    isCreateMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.commitment)

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('company')
    syncLoad.addTrigger('status')

    syncLoad.onReady(() => {
      this.$emit('ready', true)
    })

    return {
      refFormObserver,
      getValidationState,
      syncLoad,
    }
  },
  computed: {
    commitment: {
      get() {
        return this.commitmentValue
      },
      set(value) {
        this.$emit('commitmentChange', value)
      },
    },
    isInAudit() {
      return this.commitment.situation === 'audit'
    },
    isDeclined() {
      return this.commitment.situation === 'declined'
    },
    haveAuditPermission() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.audit.commitment.can_audit
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
    situationButtonClass() {
      const situationClassMap = {
        done: 'done-btn',
        idle: 'undone-btn',
        audit: 'audit-btn',
        declined: 'declined-btn',
      }

      return situationClassMap[this.commitment.situation]
    },
    situationSvgColor() {
      const situationSvgColorMap = {
        done: '#049F14',
        idle: '#777777',
        audit: '#FF7E07',
        declined: '#F50F00',
      }

      return situationSvgColorMap[this.commitment.situation]
    },
    situationSvgPath() {
      const svgPathMap = {
        done: mdiCheckCircle,
        idle: mdiCheckCircle,
        audit: mdiNoteSearchOutline,
        declined: mdiNoteSearchOutline,
      }

      return svgPathMap[this.commitment.situation]
    },
    actionText() {
      const situationTextMap = {
        done: 'Empenho Concluído',
        idle: 'Concluir Empenho',
        audit: 'Empenho Bloqueado para Auditoria',
        declined: 'Empenho Declinado pela Auditoria',
      }

      return situationTextMap[this.commitment.situation]
    },
  },
  watch: {
    'commitment.company_set': function updateCompany(value) {
      if (value) {
        this.commitment.company = value.id
      } else {
        this.commitment.company = ''
        this.commitment.company_set = ''
      }
    },
    'commitment.status_set': function updateStatus(obj) {
      if (obj) {
        this.commitment.status = obj.id
      }
    },
  },
  created() {
  },
  methods: {
    goToBidding() {
      const routeData = router.resolve({
        name: 'bidding-view',
        params: {
          id: this.commitment.bidding,
        },
      })
      window.open(routeData.href, '_blank')
    },
    changeCommitmentDone() {
      if (this.commitment.situation === 'done') {
        this.showReopenCommitmentModal()
      } else if (this.commitment.situation === 'idle') {
        this.showDoneCommitmentModal()
      } else if (this.commitment.situation === 'audit') {
        this.showReleaseAuditModal()
      }
    },
    showDoneCommitmentModal() {
      this.$bvModal
        .msgBoxConfirm('Deseja FINALIZAR todo o processo do empenho?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'd-flex justify-content-center',
          bodyClass: 'text-center',
        })
        .then(value => {
          if (value) {
            this.$emit('updateIsDone', 'done')
          }
        })
    },
    showReopenCommitmentModal() {
      this.$bvModal
        .msgBoxConfirm('Deseja REABRIR esse empenho?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'd-flex justify-content-center',
          bodyClass: 'text-center',
        })
        .then(value => {
          if (value) {
            this.$emit('updateIsDone', 'idle')
          }
        })
    },
    showReleaseAuditModal() {
      this.$refs.refAuditModal.show()
    },
    changeCommitmentAuditSituation(situation) {
      this.$refs.refAuditModal.hide()
      this.commitment.situation = situation
      this.$emit('updateAudit', situation)
    },
    checkChangeDetections(situation) {
      this.$emit('checkChanges', situation)
    },
  },
  setup() {
    return {
      mdiCheckCircle,
      mdiLaunch,
      mdiNoteSearchOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/core/colors/palette-variables.scss";

.commitmentHeader {
  background-color: #F6F6F9;
}

.commitmentInfoTitle {
  color: $primary;
  text-transform: uppercase;
}

.commitmentInfoSubtitleLabel {
  color: $primary;
  font-weight: bold;
  font-size: 1rem;
}

.commitmentInfoSubtitleContent {
  color: #494949;
  font-size: 1rem;
}

#btn-link-commitment-info {
  border: 0px !important;
}

.status-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .4em;
  max-width: 9em;
}

.right-rounded {
  border-radius: 0 1em 0 0;
}

.statusText {
  color: #ffffff;
  font-size: .9em;
  font-weight: 600;
  margin: 0;
}

.statusSvg {
  background-color: #ffffff;
  border-radius: 50%;
  padding: .4em;
}

.done-btn {
  background-color: #049F14;

  &:hover {
    box-shadow: 0 .4rem .6rem #049F1475;
  }
}

.undone-btn {
  background-color: #777777;

  &:hover {
    box-shadow: 0 .4rem .6rem #77777775;
  }
}

.audit-btn {
  background-color: #FF7E07;

  &:hover {
    box-shadow: 0 .4rem .6rem #FF7E0775;
  }
}
.releaseModalBtn {
  background-color: #049F14 !important;
  border: none !important;

  &:hover {
    box-shadow: 0 .4rem .6rem #049F1475 !important;
  }
}

.declined-btn {
  background-color: #F50F00;

  &:hover {
    box-shadow: 0 .4rem .6rem #F50F0075;
  }
}

.minModalBtnWidth {
  min-width: 8rem !important;
}

.releaseModalBtn {
  background-color: #049F14 !important;
  border: none !important;

  &:hover {
    box-shadow: 0 .4rem .6rem #049F1475 !important;
  }
}
.declineModalBtn {
  background-color: #F50F00 !important;
  border: none !important;

  &:hover {
    box-shadow: 0 .4rem .6rem #F50F0075 !important;
  }
}
</style>
