<template>
  <div id="idDeliveryList">
    <b-row>
      <b-col cols="12">
        <b-table
          thead-class="deliveryTableHeader"
          tbody-tr-class="deliveryTableRow"
          primary-key="id"
          responsive
          show-empty
          striped
          hover
          empty-text="Não há entregas cadastradas."
          :busy="isLoading"
          :items="deliveryItems"
          :fields="tableColumns"
          @row-clicked="onRowSelected"
        >
          <template #cell(field_situation)="field">
            <b-media vertical-align="center">
              <span
                v-b-tooltip.hover
                :title="setTooltipTitleAudit(field.item.situation)"
                :class="getAuditSvgClass(field.item.situation)"
              >
                <svg-icon
                  type="mdi"
                  size="22"
                  :path="mdiFileSearch"
                />
              </span>
              <span
                v-b-tooltip.hover
                :title="setTooltipTitleDone(field.item.situation)"
                class="ml-50"
                :class="field.item.situation === 'done' ? 'deliveryIsDone' : ''"
              >
                <svg-icon
                  type="mdi"
                  size="22"
                  :path="mdiCheckDecagram"
                />
              </span>
            </b-media>
          </template>

          <template #table-busy>
            <div class="text-center my-3">
              <b-spinner
                variant="info"
                class="align-middle mb-1"
              />
              <br>
              <strong class="pt-2">Carregando...</strong>
            </div>
          </template>
          <template #cell(field_company)="field">
            <b-media vertical-align="center">
              <span class="font-weight-bold d-block text-nowrap">
                {{ field.item.company_set.name_fantasy }}
              </span>
            </b-media>
          </template>

          <template #cell(field_freight_cost)="field">
            <b-media vertical-align="center">
              <span class="font-weight-bold d-block text-nowrap">
                R$ {{ field.item.freight_real | formatCurrency() }}
              </span>
            </b-media>
          </template>

          <template #cell(field_cost_total)="field">
            <b-media vertical-align="center">
              <span class="font-weight-bold d-block text-nowrap">
                R$ {{ field.item.total_value | formatCurrency() }}
              </span>
            </b-media>
          </template>

          <template #cell(field_deadline)="field">
            <b-media vertical-align="center">
              <span class="font-weight-bold d-block text-nowrap">
                <template
                  v-if="field.item.date_delivery"
                >
                  {{ moment(field.item.date_delivery).format("DD/MM/YYYY") }}
                </template>
                <template
                  v-else
                >
                  -
                </template>
              </span>
            </b-media>
          </template>

          <template #cell(field_carrier)="field">
            <b-media vertical-align="center">
              <span class="font-weight-bold d-block text-nowrap">
                {{ field.item.carrier_set ? field.item.carrier_set.name : "-" }}
              </span>
            </b-media>
          </template>

          <template #cell(field_status)="field">
            <div class="d-block text-nowrap">
              <b-badge
                pill
                class="text-capitalize"
                :style="{
                  'background-color': field.item.status_set.color,
                  'min-width': '110px',
                }"
              >
                {{ field.item.status_set.name }}
              </b-badge>
            </div>
          </template>

          <template
            v-if="canRemove && !readonly"
            #cell(actions)="field"
          >
            <div
              class="d-flex align-items-center justify-content-center"
            >
              <b-button
                v-if="!readonly"
                class="btn-icon rounded-circle"
                variant="flat-info"
                size="md"
                @click="confirmDeleteDelivery(field.item)"
              >
                <svg-icon
                  type="mdi"
                  size="20"
                  :path="mdiTrashCanOutline"
                />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BSpinner,
  BTable,
  BMedia,
  BBadge,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiCheckDecagram,
  mdiFileSearch,
  mdiTrashCanOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import moment from 'moment'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import syslic from '@/syslic'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BMedia,
    BBadge,
    BSpinner,
    BButton,
    SvgIcon,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    orderId: {
      type: [Number, String],
      default: '',
    },
    commitmentId: {
      type: [Number, String],
      required: false,
      default: '',
    },
    routerFromOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    routerFromCommitment: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    canRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const isLoading = true
    const tableColumns = [
      {
        key: 'field_situation',
        label: 'Situação',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_company',
        label: 'Empresa',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_freight_cost',
        label: 'Frete',
        thStyle: { width: '12%' },
      },
      {
        key: 'field_cost_total',
        label: 'Valor Total',
        thStyle: { width: '12%' },
      },
      {
        key: 'field_deadline',
        label: 'Prazo',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_carrier',
        label: 'Transportador',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_status',
        label: 'Status da Entrega',
        thStyle: { width: '16%' },
      },
      {
        key: 'actions',
        label: '',
        thStyle: { width: '5%' },
      },
    ]
    const deliveryItems = []

    const commitmentTotal = 0

    return {
      isLoading,
      tableColumns,
      deliveryItems,
      commitmentTotal,
    }
  },
  created() {
    this.fetchDeliveryList()
  },
  methods: {
    fetchDeliveryList() {
      this.isLoading = true
      const params = {
        order: this.orderId,
        commitment: this.commitmentId,
      }
      syslic
        .order
        .delivery
        .fetchDeliveryList(params)
        .then(response => {
          this.deliveryItems = response.data.results
          this.isLoading = false
          this.$emit('ready', true)
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.isLoading = false
          this.$emit('ready', true)
        })
    },
    onRowSelected(item) {
      if (this.readonly) {
        router.push({
          name: 'delivery-detail',
          params: {
            id: this.orderId,
            idCommitment: this.commitmentId,
            idDelivery: item.id,
            fromCommitmentPage: this.routerFromCommitment,
            fromOrderPage: this.routerFromOrder,
          },
        })
          .catch(() => {})
      } else {
        router.push({
          name: 'delivery-edit',
          params: {
            id: this.orderId,
            idCommitment: this.commitmentId,
            idDelivery: item.id,
            fromCommitmentPage: this.routerFromCommitment,
            fromOrderPage: this.routerFromOrder,
          },
        })
          .catch(() => {})
      }
    },
    getAuditSvgClass(situation) {
      const svgClassMap = {
        audit: 'deliveryInAudit',
        declined: 'deliveryDeclined',
      }

      return svgClassMap[situation] || ''
    },
    setTooltipTitleAudit(situation) {
      const tooltipTitle = {
        audit: 'Entrega em Auditoria.',
        declined: 'Entrega Declinada.',
      }

      return tooltipTitle[situation] || ''
    },
    setTooltipTitleDone(situation) {
      const tooltipTitle = {
        done: 'Entrega Concluída.',
        idle: 'Entrega Aberta.',
      }

      return tooltipTitle[situation] || ''
    },
    confirmDeleteDelivery(delivery) {
      if (delivery.situation === 'done') {
        this.$swal({
          title: 'Atenção!',
          text: 'Não é possível remover uma entrega concluída.',
          icon: 'warning',
          showConfirmButton: false,
          timer: 4000,
        })

        return
      }

      const text = delivery.items.length > 0
        ? 'Esta entrega possui um ou mais itens na sua composição, confirme se deseja concluir a remoção.'
        : 'Por favor, confirme que você deseja remover a entrega.'

      this.$bvModal
        .msgBoxConfirm(text, {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Confirmar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          contentClass: 'text-center font-weight-bold',
          footerClass: 'pb-2 justify-content-center',
          bodyClass: 'pt-2 px-1',
        })
        .then(value => {
          if (value) {
            this.deleteDelivery(delivery.id)
          }
        })
    },
    deleteDelivery(id) {
      syslic
        .order
        .delivery
        .deleteDelivery(id)
        .then(() => {
          this.$swal({
            title: 'Sucesso!',
            text: 'Entrega removida com sucesso.',
            icon: 'success',
            showConfirmButton: false,
            timer: 4000,
          })
        })
        .catch(() => {
          this.$swal({
            title: 'Falha!',
            text: 'Não foi possível remover a entrega, por favor entre em contato com o administrador do sistema.',
            icon: 'error',
            showConfirmButton: false,
            timer: 4000,
          })
        })
        .finally(() => {
          this.fetchDeliveryList()
        })
    },
  },
  setup() {
    return {
      mdiCheckDecagram,
      mdiFileSearch,
      mdiTrashCanOutline,
      moment,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idDeliveryList .deliveryTableHeader th {
  color: $primary;
  font-weight: 600;
  font-size: 0.9rem;
  background-color: #E3EDF3;
}
#idDeliveryList .itemListHeader {
  background-color: #F6F6F9;
}

#idDeliveryList .deliveryTableRow {
  cursor: pointer;
}

#idDeliveryList .headerTitle {
  color: $primary;
  font-weight: 600;
  font-size: 1.25rem;
}

#idDeliveryList .headerContent {
  color: #464646;
}

#idDeliveryList .itemIconType {
  color: $primary;
}

.deliveryIsDone {
  color: $success_2;
}

.deliveryInAudit {
  color: #FF730E;
}

.deliveryDeclined {
  color: #F50F00;
}

.textColor {
  color: $primary;
}
</style>
