<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        sm="12"
        md="12"
        class="eventBox d-flex w-100"
      >
        <div
          class="eventBoxIcon"
        >
          <div
            v-if="isFirst"
            class="eventIconBoxWhite eventIconTopBox"
          >
            <span />
          </div>
          <div
            class="eventHistoryIcon px-1"
          >
            <svg-icon
              type="mdi"
              size="18"
              :path="icon"
              class="eventIconSVG primaryColor"
            />
          </div>
          <div
            v-if="isLast"
            class="eventIconBoxWhite eventIconBottonBox"
          >
            <span />
          </div>
        </div>
        <div class="eventBoxHistory  w-100">
          <div
            class="eventHistory ml-1 mb-1 p-1  w-100"
          >
            <h4
              class="primaryColor font-weight-bolder m-0"
            >
              {{ historyType }}
            </h4>
            <span class="mr-1">{{ dateHistory }}</span>
            <span>{{ userName }}</span>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import {
  mdiGavel,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import moment from 'moment'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    SvgIcon,
  },
  props: {
    history: {
      type: [Object, String],
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLast: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const historyType = ''
    const userName = ''
    const dateHistory = ''
    const icon = ''

    return {
      historyType,
      userName,
      dateHistory,
      icon,
    }
  },
  created() {
    this.fetchUser()
    this.setValues()
  },
  methods: {
    fetchUser() {
      syslic
        .user
        .fetchUser(this.history.user)
        .then(response => {
          this.userName = response.data.first_name
        })
        .catch(() => {
          this.isLoaded = true
        })
    },
    setValues() {
      let isFind = false
      const parsedDate = moment(this.history.date)
      if (parsedDate.isValid()) {
        this.dateHistory = parsedDate.format('DD/MM/YYYY - HH:mm:ss')
      }

      this.labels.forEach(element => {
        if (!isFind) {
          if ((this.history.type === element.type) && (this.history.model === element.model)) {
            if ('validate' in element) {
              if (element.validate(this.history)) {
                this.historyType = element.label
                this.icon = element.icon
                isFind = true
              }
            } else {
              this.historyType = element.label
              this.icon = element.icon
              isFind = true
            }
          }
        }
      })
    },
  },
  setup() {
    return {
      mdiGavel,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.eventBox {
  position: relative;
}

.eventBoxIcon{
  position: absolute;
  margin-left: calc((3.5rem - 50px)/2);
  height: 100%;
  z-index: 10;
}

.eventHistoryIcon {
  background-color: #F6F6F9;
  border-radius: 100%;
  height: 3.5rem;
  width: 3.5rem;
  margin-left: 4px;
  margin-top: calc((42px + 28px - 3.5rem)/2);

  z-index: 20;
  position: relative;
}

.eventIconSVG {
  margin-top: calc((3.5rem - 18px)/2);
}

.eventIconBoxWhite {
  background-color: #fff;
  height: 50%;
  width: 100%;
  position: absolute;
  z-index: 10;
}

.eventIconTopBox {
  top: 0;
}

.eventIconBottonBox {
  bottom: 0;
}

.eventBoxHistory {
  padding-left: 2rem;
  margin-left: 2rem;

  border-left: 0.5px dashed #5E5873;
}

.eventHistory {
  background-color: #F6F6F9;
  border-radius: 10px;

  span {
    font-size: 0.9rem;
  }
}

.primaryColor{
  color: $primary;
}
</style>
