<template>
  <div>
    <div
      v-for="(history, index) in historyList"
      :key="index"
    >
      <b-row
        v-if="isOnSetup(history)"
      >
        <b-col
          cols="12"
          sm="12"
          md="12"
          class="pl-2 pr-5"
        >
          <history
            :history="history"
            :labels="labels"
            :is-first="index === getFirstIndex()"
            :is-last="index === historyList.length - 1"
            class="ml-50"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import History from './History.vue'

export default {
  components: {
    BRow,
    BCol,
    History,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    historyList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {

  },
  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
    isOnSetup(history) {
      let result = false
      this.labels.forEach(element => {
        if (history.type === element.type && history.model === element.model) {
          if ('validate' in element) {
            result = result || element.validate(history)
          } else {
            result = true
          }
        }
      })
      return result
    },
    getFirstIndex() {
      let result = -1
      this.historyList.forEach((element, index) => {
        if (this.isOnSetup(element) && result === -1) {
          result = index
        }
      })
      return result
    },
  },
  setup() {
    return {

    }
  },
}
</script>

<style lang="sass">

</style>
