<template>
  <div>
    <div>
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <b-modal
          id="idModalItemCommitment"
          ref="modalItemCommitment"
          dialog-class="mx-3 px-3"
          no-close-on-backdrop
          no-close-on-esc
          centered
          size="xl"
          cancel-variant="outline-primary"
          footer-bg-variant="light"
          :ok-disabled="invalid"
          @ok="handleSubmit(save)"
          @cancel="cancel()"
          @hidden="cancel()"
        >
          <template #modal-title>
            Selecione os Itens que deseja Incluir neste Empenho
          </template>
          <template #modal-cancel>
            Cancelar
          </template>
          <template #modal-ok>
            Adicionar
          </template>

          <b-form>
            <b-table
              ref="refItemTable"
              class="position-relative"
              thead-class="itemTableHeader"
              primary-key="id"
              responsive
              show-empty
              striped
              empty-text="Não há itens cadastrados."
              :items="itemsCommitment.items_to_commitment"
              :fields="tableColumns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center my-3">
                  <b-spinner
                    variant="info"
                    class="align-middle mb-1"
                  />
                  <br>
                  <strong class="pt-2">Carregando...</strong>
                </div>
              </template>
              <template #cell(field_lote)="field">
                <b-media vertical-align="center">
                  <span
                    v-if="field.item.item_set.parent_set"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ field.item.item_set.parent_set.name }}
                  </span>
                  <span
                    v-else
                    class="font-weight-bold d-block text-nowrap"
                  >
                    -
                  </span>
                </b-media>
              </template>

              <template #cell(field_type)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <svg-icon
                      v-if="field.item.item_set.type === 'unit'"
                      class="d-inline-block biddingIconType ml-50 inconType"
                      type="mdi"
                      :path="mdiAlphaUBoxOutline"
                    />
                    <svg-icon
                      v-if="field.item.item_set.type === 'compound'"
                      class="d-inline-block biddingIconType ml-50 inconType"
                      type="mdi"
                      :path="mdiAlphaCBox"
                    />
                  </span>
                </b-media>
              </template>

              <template #cell(field_name)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ field.item.item_set.name }}
                  </span>
                </b-media>
              </template>

              <template #cell(field_qtd)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ field.item.quantity }}
                  </span>
                </b-media>
              </template>

              <template #cell(field_cost)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    R$ {{ field.item.item_set.price | formatCurrency() }}
                  </span>
                </b-media>
              </template>

              <template #cell(field_total_cost)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    R$ {{ (field.item.item_set.price * field.item.item_set.quantity) | formatCurrency() }}
                  </span>
                </b-media>
              </template>

              <template #cell(field_actions)="field">
                <b-row>
                  <b-col
                    class="p-0 d-flex justify-content-center"
                    cols="12"
                    md="3"
                  >
                    <b-button
                      class="btn-icon rounded-circle"
                      variant="flat-primary"
                      size="md"
                      :disabled="field.item.quantity === 0"
                      @click="removeItem(field.item)"
                    >
                      <feather-icon
                        icon="MinusIcon"
                        size="16"
                      />
                    </b-button>
                  </b-col>
                  <b-col
                    class="p-0"
                    cols="12"
                    md="6"
                  >
                    <b-form-input
                      v-model="itemList[field.item.item].quantity"
                      type="number"
                      :disabled="field.item.quantity === 0"
                      @input="watchQuantity(field.item)"
                    />
                  </b-col>
                  <b-col
                    class="p-0 d-flex justify-content-center"
                    cols="12"
                    md="3"
                  >
                    <b-button
                      class="btn-icon rounded-circle"
                      variant="flat-primary"
                      size="md"
                      :disabled="field.item.quantity === 0"
                      @click="addItem(field.item)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="16"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </b-form>
        </b-modal>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BForm,
  BMedia,
  BButton,
  VBTooltip,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'

import {
  ValidationObserver,
} from 'vee-validate'

import {
  mdiAlphaUBoxOutline,
  mdiAlphaCBox,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import syslic from '@/syslic'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BForm,
    BMedia,
    BButton,
    ValidationObserver,
    SvgIcon,
    BSpinner,
    BFormInput,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
    commitmentId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    const isLoading = true

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const sortBy = 'name'
    const isSortDirDesc = false
    const tableColumns = [
      {
        key: 'field_lote',
        label: 'lote',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_type',
        label: 'tipo',
        thStyle: { width: '5%' },
      },
      {
        key: 'field_name',
        label: 'Nome do produto',
        thStyle: { width: '25%' },
      },
      {
        key: 'field_qtd',
        label: 'QTD Disponível',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_cost',
        label: 'Valor Unitário',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_total_cost',
        label: 'Valor Total',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_actions',
        label: 'QTD Selecionada',
        thStyle: { minWidth: '15rem' },
      },
    ]

    const options = {
      number: {
        numeral: true,
        numeralDecimalScale: 0,
        numeralDecimalMark: '',
        delimiter: '',
        numeralPositiveOnly: true,
      },
    }

    const itemsCommitment = {
      items_from_contract: [],
      items_from_commitment: [],
      items_to_commitment: [],
    }

    const itemList = {}

    return {
      refFormObserver,
      getValidationState,
      isLoading,
      sortBy,
      isSortDirDesc,
      itemsCommitment,
      tableColumns,
      options,
      itemList,
    }
  },
  computed: {
    data: {
      get() {
        return this.itemCommitmentValue
      },
      set(value) {
        this.$emit('itemCommitmentValueChange', value)
      },
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
  },
  methods: {
    fetchItemsList() {
      this.isLoading = true

      syslic
        .order
        .commitment
        .item
        .fetchItemsToCommitment(this.orderId)
        .then(response => {
          this.itemsCommitment = response.data

          this.itemsCommitment.items_to_commitment.forEach(element => {
            const item = {
              commitment: this.commitmentId,
              item: element.item,
              quantity: 0,
              cost: element.item_set.price,
              annotation: '',
            }

            this.itemList[element.item] = item
          })

          this.$refs.refItemTable.refresh()
          this.isLoading = false
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.isLoading = false
        })
    },
    openModal() {
      this.itemsCommitment = {
        items_from_contract: [],
        items_from_commitment: [],
        items_to_commitment: [],
      }

      this.itemList = {}
      this.fetchItemsList()
      this.$refs.modalItemCommitment.show()
    },
    save() {
      const promiseList = []
      let isEmpty = true

      Object.keys(this.itemList).forEach(key => {
        if (this.itemList[key].quantity > 0) {
          const result = syslic
            .order
            .commitment
            .item
            .addCommitmentItem(this.itemList[key])

          promiseList.push(result)

          isEmpty = false
        }
      })

      if (isEmpty) {
        this.$swal({
          title: 'Atenção!',
          text: 'Selecione pelo menos um item para adicionar ao empenho.',
          icon: 'warning',
          showConfirmButton: false,
          timer: 10000,
        })
      } else {
        Promise
          .all(promiseList)
          .then(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Itens adicionados ao empenho com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$refs.modalItemCommitment.hide()
            this.$emit('update')
          })
          .catch(error => {
            let textError = ''
            let variantAlert = 'danger'
            if ('data' in error) {
              if (error.data[0].includes('The available quantity is less than the requested quantity.')) {
                textError = 'A quantidade disponível é menor que a quantidade solicitada.'
                variantAlert = 'warning'
              }
            }
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao adicionar itens ao Empenho.',
                text: textError,
                icon: 'AlertTriangleIcon',
                variant: variantAlert,
                timeout: false,
              },
            })
            this.$refs.modalItemCommitment.hide()
          })
      }
    },
    cancel() {
    },
    watchQuantity(obj) {
      const qtd = this.itemList[obj.item].quantity

      if (qtd) {
        if (qtd > obj.quantity) {
          this.itemList[obj.item].quantity = Number(obj.quantity)
        }

        if (qtd.toString().length > obj.quantity.toString().length) {
          this.itemList[obj.item].quantity = null
        }

        if (qtd < 0) {
          this.itemList[obj.item].quantity = 0
        }
      }

      this.$refs.refItemTable.refresh()
    },
    addItem(obj) {
      const qtd = Number(this.itemList[obj.item].quantity)

      if (qtd < obj.quantity) {
        this.itemList[obj.item].quantity = qtd + 1
      }

      this.$refs.refItemTable.refresh()
    },
    removeItem(obj) {
      const qtd = Number(this.itemList[obj.item].quantity)

      if (qtd > 0) {
        this.itemList[obj.item].quantity = qtd - 1
      }

      this.$refs.refItemTable.refresh()
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiAlphaUBoxOutline,
      mdiAlphaCBox,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idModalItemCommitment {
  .modal-body{
    padding: 0 !important;
  }
  .modal-header .modal-title{
    font-size: 1.2em;
    color: $primary !important;
    font-weight: bolder;
  }
}

#idModalItemCommitment .modal-footer {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: end !important;
  background-color: #fff !important;

  .btn{
    min-width: 107px;
  }
}

[dir] .modal-footer {
  border: 0px !important;
  background-color: #fff !important;
}

.inconType {
  color: $primary;
}

#idModalItemCommitment .itemTableHeader th {
  color: $primary;
  background-color: #E3EDF3;
}

</style>
