<template>
  <div id="orderCommitmentEditPage">
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <messager
        v-if="!isCreateMode"
        :module="'commitment'"
        :thread="commitment.id"
        :header="order.contract_set.bidding_set.client_set.name"
        @ready="syncLoad.ready('messager')"
      />
      <b-row class="justify-content-center mb-1">
        <b-col
          cols="12"
          md="11"
        >
          <div>
            <b-row
              v-if="!isInAudit && !isCommitmentDone"
            >
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="isViewMode"
                    id="bnt-edit"
                    :disabled="!hasPermissionToUpdate"
                    @click="enableEdition()"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :disabled="!hasPermissionToDelete"
                    @click="showConfirmOrCancelDelete(commitment.id)"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
        >
          <validation-observer
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent
            >
              <commitment-info
                ref="commitmentInfo"
                v-model="commitment"
                :readonly="isViewMode"
                :is-edit-mode="isEditMode"
                :can-update="hasPermissionToUpdate"
                :is-create-mode="isCreateMode"
                @ready="syncLoad.ready('commitment-info')"
                @updateIsDone="updateCommitmentDone"
                @updateAudit="updateCommitmentAudit"
                @checkChanges="hasChanges"
              />
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row
        v-if="!isCreateMode"
        class="justify-content-center mt-2"
      >
        <b-col
          cols="12"
          md="11"
        >
          <div
            class="d-inline-block w-100"
          >
            <b-card no-body>
              <b-tabs
                class="commitmentTab"
                card
              >
                <b-tab
                  :active="activeTab === dataTab.commitment"
                  class="p-0"
                  @click="selectedTab(dataTab.commitment)"
                >
                  <template #title>
                    <b-card-title>
                      <svg-icon
                        type="mdi"
                        :path="mdiPackageVariant"
                      />
                      <span class="d-inline">Itens</span>
                    </b-card-title>
                  </template>
                  <b-card-body
                    class="p-0"
                  >
                    <order-item-commitment-modal
                      ref="refItemCommitmentModal"
                      :order-id="order.id"
                      :commitment-id="commitment.id"
                      :readonly="isViewMode"
                      @update="refreshItemList()"
                    />
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <order-item-commitment-list
                          ref="refItemCommitmentList"
                          :commitment="commitment"
                          :commitment-id="commitment.id"
                          :order="order"
                          :audit-percentages="auditPercentages"
                          :readonly="isViewMode"
                          @refreshListItems="refreshListItems"
                          @updateCommitmentToAudit="updateCommitmentAudit"
                          @updateSituation="updateCommitmentSituation()"
                        />
                      </b-col>
                    </b-row>
                    <b-row v-if="!isViewMode">
                      <b-col
                        class="d-flex justify-content-end"
                      >
                        <b-button
                          id="btnAddCommitment"
                          class="btn-icon rounded-circle m-1"
                          variant="primary"
                          size="md"
                          title="Adicionar Item"
                          @click="addItem()"
                        >
                          <svg-icon
                            type="mdi"
                            :path="mdiPlus"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-tab>
                <b-tab
                  v-if="!isCreateMode"
                  class="p-0"
                  :active="activeTab === dataTab.invoicing"
                  @click="selectedTab(dataTab.invoicing)"
                >
                  <template #title>
                    <b-card-title>
                      <svg-icon
                        class="mr-50 deliveryIconTitle"
                        type="mdi"
                        :path="mdiCashRegister"
                      />
                      <span class="d-inline">Faturamento</span>
                    </b-card-title>
                  </template>
                  <b-card-body
                    class="pt-0 pb-2 px-0"
                  >
                    <invoicing-table
                      ref="refInvoicingTable"
                      :filter="filter"
                      @ready="syncLoad.ready('commitment-invoicing')"
                    />
                  </b-card-body>
                </b-tab>
                <b-tab
                  v-if="!isCreateMode"
                  :active="activeTab === dataTab.delivery"
                  no-body
                  @click="selectedTab(dataTab.delivery)"
                >
                  <template #title>
                    <b-card-title>
                      <svg-icon
                        type="mdi"
                        :path="mdiTruckOutline"
                      />
                      <span class="d-inline">Entregas</span>
                    </b-card-title>
                  </template>
                  <order-deliveries-commitment-list
                    :order-id="order.id"
                    :commitment-id="commitment.id"
                    :readonly="isViewMode"
                    :router-from-commitment="true"
                    @ready="syncLoad.ready('delivery')"
                  />
                </b-tab>
                <b-tab
                  v-if="!isCreateMode"
                  :active="activeTab === dataTab.attach"
                  no-body
                  @click="selectedTab(dataTab.attach)"
                >
                  <template #title>
                    <b-card-title>
                      <svg-icon
                        type="mdi"
                        :path="mdiPaperclip"
                      />
                      <span class="d-inline">Anexos</span>
                    </b-card-title>
                  </template>
                  <b-card-body
                    class="p-0"
                  >
                    <commitment-attach
                      v-model="commitment.file_set"
                      :commitment-id="commitment.id"
                      :readonly="isViewMode"
                      @ready="syncLoad.ready('attach')"
                    />
                  </b-card-body>
                </b-tab>
                <b-tab
                  v-if="!isCreateMode"
                  class="p-0"
                  :active="activeTab === dataTab.history"
                  no-body
                  @click="selectedTab(dataTab.history)"
                >
                  <template #title>
                    <b-card-title>
                      <svg-icon
                        class="mr-50 biddingIconTitle"
                        type="mdi"
                        size="21"
                        :path="mdiClipboardTextClockOutline"
                      />
                      <span class="d-inline">Histórico</span>
                    </b-card-title>
                  </template>
                  <b-row
                    v-if="activeTab === dataTab.history"
                  >
                    <b-col class="mt-3 pb-2">
                      <commitment-history
                        :commitment-id="commitment.id"
                      />
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
          class="buttonsSaveCancel d-flex justify-content-end"
        >
          <b-button
            v-if="!isViewMode"
            id="btn-save"
            type="submit"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :disable="isFormInvalid"
            @click="onSubmit()"
          >
            Salvar
          </b-button>
          <b-button
            id="itemCancelButton"
            variant="outline-primary"
            class="mb-1 mb-sm-0"
            @click="disableEdition(true)"
          >
            Voltar
          </b-button>
        </b-col>
      </b-row>
      <b-modal
        id="idModalVerifyUnsuccess"
        ref="modalVerifyUnsuccess"
        class="modalStyle"
        centered
        hide-footer
      >
        <b-form
          class="mt-1 pb-2"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mb-2 layoutErrorModal"
              >
                <svg-icon
                  type="mdi"
                  size="80"
                  :path="mdiAlertCircle"
                />
              </span>
              <span
                class="d-flex justify-content-center px-50 pb-2 my-1 textVerify"
              >
                Falha ao Finalizar o Processo do Empenho
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="d-flex justify-content-center align-items-centered"
            >
              <ul class="importErrors">
                <li
                  v-for="(error, index) in errorsChangeSituation"
                  :key="index"
                  class="mb-50 textErrors"
                >
                  {{ error }}
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-row>
        <b-modal
          id="idModalBlockedCommitment"
          ref="refModalBlockedCommitment"
          class="modalStyle"
          no-close-on-backdrop
          no-close-on-esc
          centered
          hide-footer
          hide-header-close
        >
          <b-row>
            <b-col
              cols="12"
              class="p-3"
            >
              <span
                class="d-flex justify-content-center px-2 mb-1 layoutModal"
              >
                <svg-icon
                  type="mdi"
                  size="70"
                  :path="mdiNoteSearchOutline"
                  :style="{ 'color': '#FF7E07' }"
                />
              </span>
              <span
                class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
              >
                Empenho bloqueado para auditoria.
              </span>
            </b-col>
          </b-row>
        </b-modal>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BForm,
  BButton,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BModal,
} from 'bootstrap-vue'

import {
  ValidationObserver,
} from 'vee-validate'

import {
  mdiPackageVariant,
  mdiLaunch,
  mdiPlus,
  mdiTruckOutline,
  mdiCashRegister,
  mdiPaperclip,
  mdiTrashCanOutline,
  mdiClipboardTextClockOutline,
  mdiAlertCircle,
  mdiNoteSearchOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import OrderDeliveriesCommitmentList from '@/views/order/delivery/OrderDeliveriesCommitmentList.vue'
import OrderItemCommitmentModal from './OrderItemCommitmentModal.vue'
import OrderItemCommitmentList from './OrderItemCommitmentList.vue'
import CommitmentAttach from '@/views/order/commitment/CommitmentAttach.vue'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import Messager from '@/components/messager/Messager.vue'
import CommitmentHistory from '@/views/order/commitment/CommitmentHistory.vue'
import CommitmentInfo from '@/views/order/commitment/CommitmentInfo.vue'
import InvoicingTable from '@/views/order/invoicing/InvoicingTable.vue'
import truncate from '@/components/utils/filters/truncate'
import ChangeDetector from '@/components/utils/changeDetector'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BForm,
    BButton,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab,
    BModal,
    OrderItemCommitmentModal,
    OrderItemCommitmentList,
    CommitmentAttach,
    OrderDeliveriesCommitmentList,
    SpinLoader,
    Messager,
    CommitmentHistory,
    CommitmentInfo,
    InvoicingTable,
    SvgIcon,
  },
  directives: {
    Ripple,
  },
  filters: {
    truncate,
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.commitment)

    const changeDetector = new ChangeDetector()

    const dataTab = {
      commitment: 1,
      delivery: 2,
      attach: 3,
      history: 4,
      invoicing: 5,
    }

    let activeTab = dataTab.commitment

    let goBackTo = 'order-edit'

    const order = {
      address: '',
      contract: '',
      contract_set: {
        bidding_set: {
          client_set: '',
        },
      },
      city: '',
      city_set: '',
      company: '',
      company_set: '',
      complement: '',
      country: '',
      country_set: '',
      date_delivery: '',
      date_expiration: '',
      id: '',
      interest: '',
      interest_set: '',
      neighborhood: '',
      nf_payed: '',
      number: '',
      owner: '',
      owner_set: '',
      price: '',
      state: '',
      state_set: '',
      status: '',
      status_set: '',
      zip_code: '',
      file_set: [],
    }

    const commitment = {
      annotation_invoicing: '',
      billing_date: null,
      bidding: '',
      client: '',
      client_set: '',
      company: '',
      company_set: '',
      date_delivery: '',
      date_expiration: '',
      file_set: [],
      id: '',
      items: [],
      items_set: [],
      note_number: '',
      number: '',
      order: '',
      pay_date: null,
      real_pay_date: null,
      status: null,
      status_set: null,
      situation: 'idle',
      trade_number: '',
    }

    const auditPercentages = {
      item: 0,
      margin: 0,
    }

    const errorsChangeSituation = []
    let isViewMode = false
    let isEditMode = false
    let isCreateMode = false
    const isFormInvalid = false

    if (router.currentRoute.path.match(/.*\/edit\/.*/)) {
      isEditMode = true
    } else if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    } else {
      isViewMode = true
    }

    order.id = router.currentRoute.params.id

    if (isViewMode || isEditMode) {
      commitment.id = router.currentRoute.params.idcommitment
      activeTab = router.currentRoute.params.tab
      goBackTo = router.currentRoute.params.goBackTo
    }

    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('commitment')
    syncLoad.addTrigger('order')
    syncLoad.addTrigger('statusInitial')
    syncLoad.addTrigger('commitment-info')
    syncLoad.addTrigger('percentages')

    if (!isCreateMode) {
      syncLoad.addTrigger('messager')
      syncLoad.addTrigger('commitment-invoicing')
      syncLoad.addTrigger('delivery')
      syncLoad.addTrigger('attach')
    }

    syncLoad.onReady(() => {
      this.isLoaded = true
      this.cancelEditIfAudit()
    })

    const enabledFieldsAddress = [
      'zip_code',
      'address',
      'number',
      'complement',
      'neighborhood',
      'state',
      'city',
      'country',
    ]

    const filter = {
      page_size: 10,
      commitment: '',
    }

    return {
      changeDetector,
      auditPercentages,
      errorsChangeSituation,
      isViewMode,
      isEditMode,
      isCreateMode,
      isFormInvalid,
      refFormObserver,
      getValidationState,
      text_error: '',
      order,
      commitment,
      dataTab,
      activeTab,
      goBackTo,
      isLoaded,
      syncLoad,
      enabledFieldsAddress,
      filter,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_delete
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
    isInAudit() {
      const value = this.commitment.situation === 'audit'

      return value
    },
    isCommitmentDone() {
      const value = this.commitment.situation === 'done'

      return value
    },
  },
  watch: {
    'commitment.company_set': function updateCompany(val) {
      if (val) {
        this.commitment.company = val.id
      } else {
        this.commitment.company = ''
      }
    },
    'commitment.status_set': function updateStatus(val) {
      if (val) {
        this.commitment.status = val.id
      } else {
        this.commitment.status = ''
      }
    },
    'commitment.note_number': function updateNote(val) {
      if (val) {
        this.commitment.note_number = val
      } else {
        this.commitment.note_number = ''
      }
    },
    'commitment.annotation_invoicing': function updateAnnotation(val) {
      if (val) {
        this.commitment.annotation_invoicing = val
      } else {
        this.commitment.annotation_invoicing = ''
      }
    },
  },
  created() {
    this.fetchData()
    this.fetchOrder()
    this.fetchStatusInitial()
    this.fetchCommitmentAuditPercentages()
    this.changeDetector.bind(this, ['commitment'])
  },
  methods: {
    fetchData() {
      if (this.isCreateMode) {
        this.syncLoad.ready('commitment')
      } else {
        syslic
          .order
          .commitment
          .fetchCommitment(this.commitment.id)
          .then(response => {
            this.commitment = response.data
            this.filter.commitment = this.commitment.id
            this.syncLoad.ready('commitment')
          })
          .catch(err => {
            if (err.status === 404) {
              router.push({ name: 'error-404' })
            }
          })
          .finally(() => {
            this.changeDetector.reset()
            this.syncLoad.ready('commitment')

            if (this.commitment.situation === 'done' && !this.isViewMode) {
              router.push({
                name: 'order-commitment-detail',
                params: {
                  idcommitment: this.commitment.id,
                  tab: this.activeTab,
                  goBackTo: this.goBackTo,
                },
              }).catch({})
            }
          })
      }
    },
    fetchOrder() {
      if (this.isCreateMode) {
        syslic
          .order
          .fetchOrder(this.order.id)
          .then(response => {
            this.order = response.data
            this.commitment.order = this.order.id
            this.commitment.company = this.order.company
            this.commitment.company_set = this.order.company_set
            this.commitment.client = this.order.contract_set.bidding_set.client
            this.commitment.client_set = this.order.contract_set.bidding_set.client_set
          })
          .catch(err => {
            if (err.status === 404) {
              router.push({ name: 'error-404' })
            }
          })
          .finally(() => {
            this.changeDetector.reset()
            this.syncLoad.ready('order')
          })
      } else {
        this.syncLoad.ready('order')
      }
    },
    fetchStatusInitial() {
      if (this.isCreateMode) {
        syslic
          .order
          .commitment
          .status
          .fetchAllCommitmentStatus({
            initial: true,
          })
          .then(response => {
            if (response.data.results.length > 0) {
              const [firstResult] = response.data.results
              this.commitment.status = firstResult.id
              this.commitment.status_set = firstResult
            } else {
              this.commitment.status = null
              this.commitment.status_set = null
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao carregar status inicial.',
                text:
                  'Não foi possível carregar informações de status, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          })
          .finally(() => {
            this.syncLoad.ready('statusInitial')
            this.changeDetector.reset()
          })
      } else {
        this.syncLoad.ready('statusInitial')
      }
    },
    fetchCommitmentAuditPercentages() {
      syslic
        .order
        .audit
        .fetchPercentages()
        .then(response => {
          this.auditPercentages = {
            item: response.data.commitment_percentage,
            margin: response.data.commitment_margin_percentage,
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar informações de auditoria.',
              text:
                'Não foi possível carregar informações de auditoria, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
        .finally(() => {
          this.syncLoad.ready('percentages')
        })
    },
    onSubmit() {
      this.commitment.order = this.order.id
      this.$refs.refFormObserver.validate().then(success => {
        this.isFormInvalid = !success
        if (!this.isFormInvalid) {
          if (this.isCreateMode) {
            this.createCommitment()
          } else {
            this.updateCommitment()
          }
        }
      })
    },
    createCommitment() {
      syslic
        .order
        .commitment
        .addCommitment(this.commitment)
        .then(response => {
          this.commitment = response.data
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Empenho criado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.isCreateMode = false
          router.push({
            name: 'order-commitment-edit',
            params: {
              idcommitment: this.commitment.id,
              tab: this.activeTab,
              goBackTo: this.goBackTo,
            },
          })
        })
        .catch(error => {
          let textError = 'Não foi possível adicionar o empenho, por favor entre em contato com o administrador do sistema.'

          if ('non_field_errors' in error.data) {
            if (error.data.non_field_errors[0] === 'The fields order, number must make a unique set.') {
              textError = 'Não foi possivel criar o empenho porque já existe um empenho cadastrado com o mesmo Nº Empenho.'
            }
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao criar o Empenho.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    updateCommitment() {
      syslic
        .order
        .commitment
        .updateCommitment(this.commitment.id, this.commitment)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Empenho atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition(false)
        })
        .catch(error => {
          let textError = 'Não foi possível atualizar o empenho, por favor entre em contato com o administrador do sistema.'

          if ('non_field_errors' in error.data) {
            if (error.data.non_field_errors[0] === 'The fields order, number must make a unique set.') {
              textError = 'Não foi possivel atualizar o empenho porque já existe um empenho cadastrado com o mesmo Nº Empenho.'
            }
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar o Empenho.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
        .finally(() => {
          this.changeDetector.reset()
        })
    },
    deleteCommitment(item) {
      syslic
        .order
        .commitment
        .deleteCommitment(item)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Empenho removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition(true)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover Empenho.',
              text: 'Não foi possível remover o empenho do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o empenho.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteCommitment(item)
          }
        })
    },
    enableEdition() {
      router.push({
        name: 'order-commitment-edit',
        params: {
          idcommitment: this.commitment.id,
          tab: this.activeTab,
          goBackTo: this.goBackTo,
        },
      })
        .catch(() => {})
    },
    disableEdition(isGoBack) {
      if (this.isCreateMode || isGoBack) {
        if (this.goBackTo) {
          router.push({
            name: `${this.goBackTo}`,
          })
        } else if (this.isViewMode) {
          router.push({
            name: 'order-detail',
            params: {
              id: this.order.id,
            },
          })
            .catch(() => {})
        } else {
          router.push({
            name: 'order-edit',
            params: {
              id: this.order.id,
            },
          })
            .catch(() => {})
        }
      } else {
        router.push({
          name: 'order-commitment-detail',
          params: {
            idcommitment: this.commitment.id,
            tab: this.activeTab,
            goBackTo: this.goBackTo,
          },
        })
          .catch(() => {})
      }
    },
    selectedTab(value) {
      this.activeTab = value
    },
    addItem() {
      this.$refs.refItemCommitmentModal.openModal()
    },
    refreshItemList() {
      this.$refs.refItemCommitmentList.refresh()
    },
    refreshListItems(value) {
      if (value) {
        this.commitment.items = []
        this.commitment.items_set = []

        value.forEach(element => {
          this.commitment.items.push(element.id)
          this.commitment.items_set.push(element)
        })

        this.changeDetector.reset()
      }
    },
    goToBidding() {
      const routeData = router.resolve({
        name: 'bidding-view',
        params: {
          id: this.commitment.bidding,
        },
      })
      window.open(routeData.href, '_blank')
    },
    updateCommitmentDone(val) {
      syslic
        .order
        .commitment
        .updateSituation(this.commitment.id, val)
        .then(() => {
          this.commitment.situation = val
          const res = val === 'done' ? 'finalizado' : 'reaberto'

          this.$swal({
            title: '',
            text: `Empenho ${res} com sucesso!`,
            icon: 'success',
            showConfirmButton: false,
            timer: 5000,
          })
        })
        .catch(error => {
          this.errorsChangeSituation = []
          if ('data' in error) {
            if (error.data.errors.includes('note_number is required to finalize commitment')) {
              this.errorsChangeSituation.push('O campo Nº da Nota dentro da aba de Faturamento precisa estar preenchido.')
            }
            if (error.data.errors.includes('billing_date is required to finalize commitment')) {
              this.errorsChangeSituation.push('O campo Data de Faturamento dentro da aba de Faturamento precisa estar preenchido.')
            }
            if (error.data.errors.includes('pay_date is required to finalize commitment')) {
              this.errorsChangeSituation.push('O campo Previsão de Pagamento dentro da aba de Faturamento precisa estar preenchido.')
            }
            if (error.data.errors.includes('real_pay_date is required to finalize commitment')) {
              this.errorsChangeSituation.push('O campo Data de Pagamento dentro da aba de Faturamento precisa estar preenchido.')
            }
            if (error.data.errors.includes('there is an undelivered item')) {
              this.errorsChangeSituation.push('Todos os itens do empenho precisam ter sido entregues.')
            }
            if (error.data.errors.includes('there is incomplete delivery')) {
              this.errorsChangeSituation.push('Todas as entregas relacionadas a este empenho devem estar concluidas.')
            }
            if (error.data.errors.includes('there is no item registered')) {
              this.errorsChangeSituation.push('O empenho não possui item cadastrado.')
            }
          }
          if (this.errorsChangeSituation.length > 0) {
            this.openModalVerifyUnsuccess()
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao atualizar a situação do Empenho.',
                text:
                  'Não foi possível atualizar a situação da empenho, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          }
        })
        .finally(() => {
          this.changeDetector.reset()
        })
    },
    updateCommitmentAudit(val) {
      syslic
        .order
        .commitment
        .updateSituation(this.commitment.id, val)
        .then(() => {
          this.commitment.situation = val
          if (val === 'audit') {
            this.showBlockedCommitmentModal()

            setTimeout(() => {
              this.disableEdition(false)
            }, 4000)
          } else {
            const res = val === 'idle' ? 'reaberto' : 'declinado'

            this.$swal({
              title: '',
              text: `Empenho ${res} com sucesso!`,
              icon: val === 'idle' ? 'success' : 'error',
              showConfirmButton: false,
              timer: 5000,
            })

            router.push({
              name: 'order-commitment-edit',
              params: {
                idcommitment: this.commitment.id,
                tab: this.activeTab,
                goBackTo: this.goBackTo,
              },
            })
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar a situação do Empenho.',
              text:
                'Não foi possível atualizar a situação da empenho, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
        .finally(() => {
          this.changeDetector.reset()
        })
    },
    updateCommitmentSituation() {
      this.isLoaded = false
      syslic
        .order
        .commitment
        .fetchCommitment(this.commitment.id)
        .then(response => {
          this.commitment.situation = response.data.situation
          this.isLoaded = true
        })
        .catch(() => {
          const textError = 'Não foi possível atualizar o item do empenho no servidor, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar item.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.isLoaded = true
        })
        .finally(() => {
          this.changeDetector.reset()
        })
    },
    openModalVerifyUnsuccess() {
      this.$refs.modalVerifyUnsuccess.show()
    },
    showBlockedCommitmentModal() {
      this.$refs.refModalBlockedCommitment.show()
    },
    cancelEditIfAudit() {
      if (router.currentRoute.path.match(/.*\/edit\/.*/)) {
        if (this.isInAudit) {
          this.disableEdition(false)
        }
      }
    },
    hasChanges(situation = null) {
      if (this.changeDetector.hasChanges()) {
        return
      }
      if (situation) {
        this.$refs.commitmentInfo.changeCommitmentAuditSituation(situation)
      } else {
        this.$refs.commitmentInfo.changeCommitmentDone()
      }
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPackageVariant,
      mdiLaunch,
      mdiPlus,
      mdiTruckOutline,
      mdiCashRegister,
      mdiPaperclip,
      mdiTrashCanOutline,
      mdiClipboardTextClockOutline,
      mdiAlertCircle,
      mdiNoteSearchOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#orderCommitmentEditPage {
  transform: none !important;
}

.disabledEdition .input-group-text{
  background-color: #efefef;
}

.commitmentHeader {
  background-color: #F6F6F9;
}

.commitmentInfoTitle {
  color: $primary;
  text-transform: uppercase;
}

#btn-link-bidding-info {
  border: none !important;;
}

.commitmentInfoSubtitleLabel {
  color: $primary;
  font-weight: bold;
  font-size: 1rem;
}

.commitmentInfoSubtitleContent {
  color: #494949;
  font-size: 1rem;
}

.commitmentTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 0;
}

.commitmentTab .card-header-tabs {
  margin-bottom: 0;
}

.commitmentTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.commitmentTab .nav-link h4 {
  color: #6e6b7b;
  font-size: 1rem;
}

.commitmentTab .nav-link.active {
  background-color: #e2edf3;
}

.commitmentTab .nav-link.active h4 {
  color: $primary;
}

.commitmentTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}

.textVerify {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
}

.textErrors {
  color: #e77000;
  font-weight: 400;
}

.layoutErrorModal {
  svg {
    color: #ff8b1e;
  }
}
</style>
