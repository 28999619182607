<template>
  <div>
    <div id="idItemCommitmentList">
      <b-row class="itemListHeader mx-0">
        <b-col
          cols="12"
          md="6"
        >
          <div class="ml-50">
            <p class="mt-2 mb-50 headerTitle">
              Valor do Empenho
            </p>
            <p class="mb-2 headerContent">
              R$ {{ commitmentTotal | formatCurrency() }}
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <b-table
            ref="refItemTable"
            thead-class="itemTableHeader"
            primary-key="id"
            responsive
            show-empty
            striped
            hover
            empty-text="Não há itens cadastrados."
            :items="itemsCommitment"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy="isLoading"
            @row-clicked="edit"
          >
            <template #table-busy>
              <div class="text-center my-3">
                <b-spinner
                  variant="info"
                  class="align-middle mb-1"
                />
                <br>
                <strong class="pt-2">Carregando...</strong>
              </div>
            </template>
            <template #cell(field_lote)="field">
              <b-media vertical-align="center">
                <span
                  v-if="field.item.contract_item.parent_set"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.contract_item.parent_set.name }}
                </span>
                <span
                  v-else
                  class="font-weight-bold d-block text-nowrap"
                >
                  -
                </span>
              </b-media>
            </template>

            <template #cell(field_delivery)="field">
              <b-media vertical-align="center">
                <svg-icon
                  v-if="field.item.deliverable"
                  class="d-inline-block iconDeliverySuccess"
                  type="mdi"
                  :path="mdiTruckDeliveryOutline"
                />
                <svg-icon
                  v-else
                  class="d-inline-block"
                  type="mdi"
                  :path="mdiTruckRemoveOutline"
                />
              </b-media>
            </template>

            <template #cell(field_type)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  <svg-icon
                    v-if="field.item.contract_item.type === 'unit'"
                    class="d-inline-block biddingIconType ml-50 inconType"
                    type="mdi"
                    :path="mdiAlphaUBoxOutline"
                  />
                  <svg-icon
                    v-if="field.item.contract_item.type === 'compound'"
                    class="d-inline-block biddingIconType ml-50 inconType"
                    type="mdi"
                    :path="mdiAlphaCBox"
                  />
                </span>
              </b-media>
            </template>

            <template #cell(field_name)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.contract_item.name }}
                </span>
              </b-media>
            </template>

            <template #cell(field_qtd)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.quantity }}
                </span>
              </b-media>
            </template>

            <template #cell(field_cost)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  R$ {{ get_unitary_value(field.item) | formatCurrency() }}
                </span>
              </b-media>
            </template>

            <template #cell(field_total_cost)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  R$ {{ (get_unitary_value(field.item) * field.item.quantity) | formatCurrency() }}
                </span>
              </b-media>
            </template>

            <template #cell(field_audit_indicators)="field">
              <div class="d-flex align-items-center justify-content-center">
                <b-media
                  v-if="belowProfitabilityMargin(field.item)"
                  v-b-tooltip.hover.bottomleft
                  class="mr-50"
                  vertical-align="center"
                  title="Produto com margem de lucratividade abaixo do permitido."
                >
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <svg-icon
                      class="alertColor"
                      type="mdi"
                      :path="mdiPercentCircle"
                    />
                  </span>
                </b-media>
                <b-media
                  v-if="aboveEstimatedPrice(field.item)"
                  v-b-tooltip.hover.bottomright
                  vertical-align="center"
                  title="Produto com item acima do permitido sobre o valor estimado."
                >
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <svg-icon
                      class="alertColor"
                      type="mdi"
                      :path="mdiTruckAlert"
                    />
                  </span>
                </b-media>
              </div>
            </template>

            <template #cell(field_actions)="field">
              <b-button
                class="btn-icon rounded-circle ml-50"
                variant="flat-info"
                size="md"
                @click="showConfirmOrCancelDelete(field.item.id, field.index)"
              >
                <svg-icon
                  type="mdi"
                  size="18"
                  :path="mdiTrashCanOutline"
                />
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div>
        <order-comm-item-edit-modal
          ref="refItemCommitmentModal"
          v-model="itemValue"
          :commitment="commitment"
          :readonly="readonly"
          :audit-percentages="auditPercentages"
          @update="refresh()"
          @updateAudit="emitUpdateCommitment($event)"
          @updateSituation="updateCommitmentSituation()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BMedia,
  BButton,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'

import {
  mdiAlphaUBoxOutline,
  mdiAlphaCBox,
  mdiTruckRemoveOutline,
  mdiTruckDeliveryOutline,
  mdiTrashCanOutline,
  mdiTruckAlert,
  mdiPercentCircle,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import syslic from '@/syslic'
import router from '@/router'
import OrderCommItemEditModal from './OrderCommItemEditModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BMedia,
    BButton,
    SvgIcon,
    BSpinner,
    OrderCommItemEditModal,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    commitment: {
      type: [Object, String],
      required: false,
      default: null,
    },
    order: {
      type: [Object, String],
      required: false,
      default: null,
    },
    auditPercentages: {
      type: Object,
      required: true,
    },
    commitmentId: {
      type: [Number, String],
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const isLoading = true

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const sortBy = 'name'
    const isSortDirDesc = false
    const tableColumns = [
      {
        key: 'field_lote',
        label: 'lote',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_delivery',
        label: 'Status para Entrega',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_type',
        label: 'Tipo',
        thStyle: { width: '5%' },
      },
      {
        key: 'field_name',
        label: 'Nome do produto',
        thStyle: { width: '25%' },
      },
      {
        key: 'field_qtd',
        label: 'QTD',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_cost',
        label: 'Valor Unitário',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_total_cost',
        label: 'Valor Total',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_audit_indicators',
        label: '',
        thStyle: { width: '5%' },
      },
    ]

    if (!this.readonly) {
      tableColumns.push({
        key: 'field_actions',
        label: '',
        thStyle: { width: '5%' },
      })
    }

    const itemsCommitment = []

    const commitmentTotal = 0

    const itemValue = {
      annotation: '',
      contract_item: {
        product_list: [{
          product_set: '',
        }],
      },
      commitment: '',
      cost: '',
      id: '',
      item: '',
      quantity: '',
    }

    return {
      refFormObserver,
      getValidationState,
      isLoading,
      sortBy,
      tableColumns,
      isSortDirDesc,
      itemsCommitment,
      commitmentTotal,
      itemValue,
    }
  },
  computed: {
  },
  created() {
    this.fetchItemsList()
  },
  methods: {
    fetchItemsList() {
      this.isLoading = true

      const params = {
        commitment: this.commitmentId,
      }

      syslic
        .order
        .commitment
        .item
        .fetchAllCommitmentItem(params)
        .then(response => {
          this.itemsCommitment = response.data.results
          this.$emit('refreshListItems', this.itemsCommitment)
          this.isLoading = false
          this.refreshCommitmentTotal()
          this.$refs.refItemTable.refresh()
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.isLoading = false
        })
    },
    refresh() {
      this.fetchItemsList()
    },
    refreshCommitmentTotal() {
      let total = 0
      this.itemsCommitment.forEach(element => {
        total += (this.get_unitary_value(element) * element.quantity)
      })

      this.commitmentTotal = total
    },
    deleteItem(id, index) {
      syslic
        .order
        .commitment
        .item
        .deleteCommitmentItem(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Item removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.itemsCommitment.splice(index, 1)
          this.refreshCommitmentTotal()
          this.$emit('refreshListItems', this.itemsCommitment)
          this.$refs.refItemTable.refresh()
        })
        .catch(error => {
          let textError = 'Não foi possível remover o item do empenho do servidor, por favor entre em contato com o administrador do sistema.'
          let variantType = 'danger'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Este item é referenciado em alguma entrega, por este motivo não será possível remove-lo.'
              variantType = 'warning'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover item.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: variantType,
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(id, index) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o item do empenho.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteItem(id, index)
          }
        })
    },
    edit(value) {
      this.itemValue = value
      if (this.itemValue.contract_item.type === 'unit') {
        this.$refs.refItemCommitmentModal.openUnitModal(this.itemValue)
      } else {
        this.$refs.refItemCommitmentModal.openCompoundModal(this.itemValue)
      }
    },
    get_unitary_value(item) {
      let value = 0
      value = Number(item.contract_item.price)
      return value
    },
    emitUpdateCommitment(audit) {
      this.$emit('updateCommitmentToAudit', audit)
    },
    updateCommitmentSituation() {
      this.$emit('updateSituation')
    },
    belowProfitabilityMargin(product) {
      let costUnitary = null
      let freightFob = null

      if (product.products_set.length > 0) {
        costUnitary = 0
        freightFob = 0

        product.products_set.forEach(element => {
          costUnitary += element.cost * element.product_set.quantity
          freightFob += Number(element.fob_freight)
        })
      }

      const result = product.contract_item.price
        - ((product.contract_item.price * (product.contract_item.fixed_cost / 100))
          + (product.contract_item.price * (product.contract_item.freight / 100))
          + freightFob
          + costUnitary)

      const margin = Number(parseFloat((result / product.contract_item.price) * 100).toFixed(2))
      const auditMargin = Number(parseFloat(this.auditPercentages.margin).toFixed(2))

      return margin < auditMargin
    },
    aboveEstimatedPrice(product) {
      let isAbove = false

      product.products_set.forEach(element => {
        if (((element.cost / element.product_set.price) - 1) * 100 > this.auditPercentages.item) {
          isAbove = true
        }
      })

      return isAbove
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiAlphaUBoxOutline,
      mdiAlphaCBox,
      mdiTruckRemoveOutline,
      mdiTruckDeliveryOutline,
      mdiTrashCanOutline,
      mdiTruckAlert,
      mdiPercentCircle,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idItemCommitmentList .itemTableHeader th {
  color: $primary;
  background-color: #E3EDF3;
  vertical-align: middle;
}

#idItemCommitmentList .itemListHeader {
  background-color: #F6F6F9;

}

#idItemCommitmentList .headerTitle {
  color: $primary;
  font-weight: 600;
  font-size: 1.25rem;
}

#idItemCommitmentList .headerContent {
  color: #464646;
}

#idItemCommitmentList .iconDeliverySuccess {
  color: #02B714;
}

.alertColor {
  color: #F50F00;
}
</style>
