<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        v-if="visibleFields.length !== 0"
        class="position-relative invoicingTableView"
        primary-key="number"
        show-empty
        responsive
        striped
        hover
        empty-text="Não há faturamentos cadastrados."
        :items="invoicings"
        :fields="visibleFields"
        :busy="isLoading"
        :sort-by.sync="sortOptions.sortBy"
        :sort-desc.sync="sortOptions.isSortDirDesc"
        tbody-tr-class="invoicingTableCustomHeight"
        @row-clicked="onRowSelected"
      >
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner
              variant="info"
              class="align-middle mb-1"
            />
            <br>
            <strong class="pt-2">Carregando ...</strong>
          </div>
        </template>

        <template #cell(id)="field">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'invoicing-detail', params: { id: field.item.id } }"
              class="font-weight-bold d-block text-nowrap text-right"
            >
              #{{ field.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(situation)="field">
          <div
            v-b-tooltip.hover.bottomright
            :title="textTooltip[field.item.situation]"
            class="d-flex justify-content-center"
          >
            <svg-icon
              class="mr-50"
              :class="field.item.situation === 'released' ? 'currentSituation' : ''"
              type="mdi"
              :path="mdiTruckDeliveryOutline"
            />
            <svg-icon
              :class="field.item.situation === 'done' ? 'currentSituation' : ''"
              type="mdi"
              :path="mdiCheckDecagram"
            />
          </div>
        </template>

        <template #cell(note_number)="field">
          <span
            v-if="field.item.note_number"
            class="text-nowrap font-weight-bold"
          >
            {{ field.item.note_number }}
          </span>
          <span
            v-else
            class="text-nowrap font-weight-bold"
          >
            -
          </span>
        </template>

        <template #cell(delivery__id)="field">
          <span class="text-nowrap font-weight-bold">
            {{ field.item.delivery }}
          </span>
        </template>

        <template #cell(delivery__company__corporate_name)="field">
          <span
            v-if="field.item.delivery_set.company"
            class="text-nowrap font-weight-bold"
          >
            {{ field.item.delivery_set.company_set.corporate_name }}
          </span>
          <span v-else> - </span>
        </template>

        <template #cell(commitment__number)="field">
          <span class="text-nowrap font-weight-bold">
            {{ field.item.commitment_set.number }}
          </span>
        </template>

        <template #cell(commitment__order__contract__bidding__client__name)="field">
          <span
            v-if="field.item.commitment_set.client_set"
            class="text-nowrap font-weight-bold"
          >
            {{ field.item.commitment_set.client_set.name }}
          </span>
          <span v-else> - </span>
        </template>

        <template #cell(delivery__date_delivery)="field">
          <span class="text-nowrap font-weight-bold">
            {{ moment(field.item.delivery_set.date_delivery).format('DD/MM/YYYY') || '' }}
          </span>
        </template>

        <template #cell(delivery__invoicing_delivery_date)="field">
          <span
            v-if="field.item.delivery_set.invoicing_delivery_date"
            class="text-nowrap font-weight-bold"
          >
            {{ moment(field.item.delivery_set.invoicing_delivery_date).format('DD/MM/YYYY') || '' }}
          </span>
          <span
            v-else
            class="text-nowrap font-weight-bold"
          >
            -
          </span>
        </template>

        <template #cell(real_pay_date)="field">
          <span
            v-if="field.item.real_pay_date"
            class="text-nowrap font-weight-bold"
          >
            {{ moment(field.item.real_pay_date).format('DD/MM/YYYY') || '' }}
          </span>
          <span
            v-else
            class="text-nowrap font-weight-bold"
          >
            -
          </span>
        </template>

        <template #cell(days_delay)="field">
          <span
            class="text-nowrap font-weight-bold"
          >
            {{ daysDelayList[field.item.id] }}
          </span>
        </template>

        <template #cell(total_nf)="field">
          <span
            class="text-nowrap"
          >
            R$ {{ field.item.total_nf | formatCurrency() }}
          </span>
        </template>

        <template #cell(liquid_margin)="field">
          <span
            class="text-nowrap"
          >
            {{ field.item.liquid_margin | formatCurrency() }} %
          </span>
        </template>

        <template #cell(status__name)="field">
          <div
            class="d-block text-nowrap font-weight-bold"
          >
            <b-badge
              pill
              class="badgeStyle text-capitalize sizeMin d-inline-flex align-items-center justify-content-center"
              :style="{'background-color': field.item.status_set.color}"
            >
              {{ field.item.status_set.name }}
            </b-badge>
          </div>
        </template>

      </b-table>
      <div
        class="mx-2 mb-2"
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-if="visibleFields.length !== 0"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="filter.page_size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  VBTooltip,
  BBadge,
  BSpinner,
  BMedia,
  BLink,
} from 'bootstrap-vue'

import {
  mdiTruckDeliveryOutline,
  mdiCheckDecagram,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import SvgIcon from '@jamescoyle/vue-icon'
import moment from 'moment'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import MetaData from '@/components/utils/metaData'
import debounce from '@/components/utils/debounce'
import formatCurrency from '@/components/utils/filters/formatCurrency'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BBadge,
    BSpinner,
    BMedia,
    BLink,
    SvgIcon,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['ready'],

  data() {
    const totalItems = 0
    const currentPage = 1
    const perPage = 10

    const sortOptions = {
      sortBy: 'id',
      isSortDirDesc: false,
    }

    const lastSearch = ''

    const invoicings = []

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'situation',
        label: 'Situação',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'note_number',
        label: 'Nº da Nota',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'delivery__id',
        label: 'Nº Entrega',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'delivery__company__corporate_name',
        label: 'Empresa',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'commitment__number',
        label: 'Empenho',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'commitment__order__contract__bidding__client__name',
        label: 'Órgão',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'delivery__date_delivery',
        label: 'Prazo da Entrega',
        visible: false,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'delivery__invoicing_delivery_date',
        label: 'Data da Entrega',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'real_pay_date',
        label: 'Data de Pagamento',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'days_delay',
        label: 'Dias em Atraso',
        visible: true,
        fixedColumn: false,
        sortable: false,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'total_nf',
        label: 'Total da NF',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'liquid_margin',
        label: 'Margem Líquida',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
      {
        key: 'status__name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap invoicingTableColumn',
      },
    ]

    const fetchId = ''

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('invoicings')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const meta = new MetaData('InvoicingTable')

    const textTooltip = {
      idle: 'Faturamento aberto.',
      released: 'Faturamento liberado para entrega.',
      done: 'Faturamento concluído.',
    }

    const daysDelayList = []

    return {
      fetchId,
      totalItems,
      perPage,
      currentPage,
      lastSearch,
      syncLoad,
      invoicings,
      tableColumns,
      isLoading,
      sortOptions,
      meta,
      textTooltip,
      daysDelayList,
    }
  },
  computed: {
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
  },
  watch: {
    filter: {
      handler: function updateFilter(val) {
        this.currentPage = 1
        if (this.lastSearch !== val.search) {
          this.lastSearch = val.search
        }
        this.fetchData()
      },
      deep: true,
    },
    refresh: function refreshFilter(val) {
      if (val) {
        this.fetchData()
      }
    },
    'sortOptions.sortBy': function updateSortBy() {
      this.currentPage = 1
      this.fetchData()
    },
    'sortOptions.isSortDirDesc': function updateIsSortDirDesc() {
      this.currentPage = 1
      this.fetchData()
    },
    currentPage: function updateCurrentPage() {
      this.fetchData()
    },
  },
  created() {
    this.sortOptions = this.meta.bind(this.sortOptions, 'sortOptions')
    this.fetchData()
  },
  methods: {
    refreshColumns(columns) {
      columns.forEach(element => {
        this.tableColumns.forEach((obj, index) => {
          if (obj.key === element.key) {
            this.tableColumns[index].visible = element.visible
          }
        })
      })
    },
    fetchData() {
      this.isLoading = true
      debounce.call(this.fetch)
    },
    fetch() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      const invoicingFilter = this.filter
      invoicingFilter.p = this.currentPage

      if (this.sortOptions.isSortDirDesc) {
        invoicingFilter.ordering = `-${this.sortOptions.sortBy}`
      } else {
        invoicingFilter.ordering = this.sortOptions.sortBy
      }

      syslic
        .order
        .invoicing
        .fetchInvoicingList(invoicingFilter)
        .then(response => {
          let promise = {}

          if (fetchPending === this.fetchId) {
            this.totalItems = response.data.count
            this.invoicings = response.data.results

            this.invoicings.forEach(element => {
              this.calculateDelay(element)
            })

            promise = new Promise(resolve => {
              resolve()
            })
          } else {
            promise = new Promise((resolve, reject) => {
              reject()
            })
          }

          return promise
        })
        .then(() => {
          this.syncLoad.ready('invoicings')
          this.$emit('change-refresh', false)
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados dos faturamentos',
                text: 'Não foi possível ler os dados dos faturamentos do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: 'false',
              },
            })
            this.syncLoad.ready('invoicings')
          }
        })
    },
    onRowSelected(item) {
      router.push({
        name: 'invoicing-detail',
        params: {
          id: item.id,
          tab: 6,
        },
      })
        .catch(() => {})
    },
    calculateDelay(item) {
      let hasDaysDelay = false
      let daysDelay = -1
      this.daysDelayList[item.id] = '-'

      if (item.delivery_set.invoicing_delivery_date) {
        const deliveryDate = moment(item.delivery_set.invoicing_delivery_date)

        if (!Number.isNaN(Date.parse(deliveryDate))) {
          const res = item.real_pay_date ? moment(item.real_pay_date) : moment()
          daysDelay = res.diff(moment(deliveryDate), 'days')
          hasDaysDelay = daysDelay > -1
        }

        if (hasDaysDelay) {
          const text = daysDelay < 2 ? 'dia' : 'dias'
          this.daysDelayList[item.id] = `${daysDelay} ${text}`
        }
      }
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
      mdiTruckDeliveryOutline,
      mdiCheckDecagram,
    }
  },
}
</script>

<style lang="scss">
.invoicingTableView {
  border-radius: .4rem;
}

.invoicingTableColumn {
  min-width: 6rem;
}

.invoicingTableCustomHeight {
  height: 4.5rem;
}

.badgeStyle {
  height: 2.2rem;
  min-width: 10rem;
  font-size: 1rem;
}

.currentSituation {
  color: #049B14;
}
</style>
