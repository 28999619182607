<template>
  <div>
    <div>
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <b-modal
          id="idModalItemCommitment"
          ref="modalItemCommitment"
          dialog-class="mx-3 px-3"
          no-close-on-backdrop
          no-close-on-esc
          centered
          size="xl"
          cancel-variant="outline-primary"
          footer-bg-variant="light"
          :ok-disabled="invalid"
          :hide-footer="readonly"
          @ok="handleSubmit(checkAuditPercentages)"
          @hidden="cancel()"
        >
          <template #modal-title>
            Cotação de Item
          </template>
          <template #modal-footer="{ ok }">
            <b-row
              class="w-100"
            >
              <b-col
                cols="12"
                md="6"
              >
                <b-form-checkbox
                  v-model="data.deliverable"
                  name="check-button"
                  :button-variant="data.deliverable ? 'success' : 'outline-info'"
                  button
                  size="sm"
                >
                  <svg-icon
                    v-if="data.deliverable"
                    class="d-inline-block"
                    type="mdi"
                    :path="mdiCheckboxOutline"
                  />
                  <svg-icon
                    v-else
                    class="d-inline-block"
                    type="mdi"
                    :path="mdiCheckboxBlankOutline"
                  />
                  <span
                    v-if="data.deliverable"
                    class="ml-50"
                  >
                    Liberado para Entrega
                  </span>
                  <span
                    v-else
                    class="ml-50"
                  >
                    Marcar para Entrega
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <div
                  class="text-right"
                >
                  <b-button
                    class="d-inline mr-1"
                    variant="primary"
                    @click="ok()"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    class="d-inline"
                    variant="outline-primary"
                    @click="cancel()"
                  >
                    Cancelar
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </template>
          <b-form>
            <b-table
              ref="refItemTable"
              class="position-relative"
              thead-class="itemTableHeader"
              primary-key="id"
              responsive
              show-empty
              striped
              empty-text="Não há itens cadastrados."
              :items="itemCommitment"
              :fields="tableColumns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center my-3">
                  <b-spinner
                    variant="info"
                    class="align-middle mb-1"
                  />
                  <br>
                  <strong class="pt-2">Carregando...</strong>
                </div>
              </template>

              <template #cell(type)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <svg-icon
                      v-if="field.item.contract_item.type === 'unit'"
                      class="d-inline-block biddingIconType ml-50 inconType"
                      type="mdi"
                      :path="mdiAlphaUBoxOutline"
                    />
                    <svg-icon
                      v-if="field.item.contract_item.type === 'compound'"
                      class="d-inline-block biddingIconType ml-50 inconType"
                      type="mdi"
                      :path="mdiAlphaCBox"
                    />
                  </span>
                </b-media>
              </template>

              <template #cell(name)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ field.item.contract_item.name }}
                  </span>
                </b-media>
              </template>

              <template #cell(quantity)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ field.item.quantity }}
                  </span>
                </b-media>
              </template>

              <template #cell(price)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    R$ {{ field.item.contract_item.price | formatCurrency() }}
                  </span>
                </b-media>
              </template>

              <template #cell(total_cost)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    R$ {{ (field.item.contract_item.price * field.item.quantity) | formatCurrency() }}
                  </span>
                </b-media>
              </template>

              <template #cell(profitability)="field">
                <b-media vertical-align="center">
                  <span
                    v-if="hasAllNegotiatedValue()"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ calculateProfitability(field.item) | formatCurrency() }} %
                  </span>
                  <span
                    v-else
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <svg-icon
                      class="d-inline-block ml-1"
                      type="mdi"
                      :path="mdiMinus"
                    />
                  </span>
                </b-media>
              </template>

              <template #cell(below_profitability)="field">
                <b-media
                  v-if="isBelowProfitability(field.item)"
                  vertical-align="center"
                >
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <svg-icon
                      v-b-tooltip.hover.bottomright
                      title="Produto com margem de lucratividade abaixo do permitido."
                      class="d-inline-block ml-1 alertColor"
                      type="mdi"
                      :path="mdiArrowDownBoldCircle,"
                    />
                  </span>
                </b-media>
              </template>
            </b-table>
            <div>
              <b-row
                class="px-2"
              >
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Marca"
                    label-for="brand"
                  >
                    <b-form-input
                      id="brand"
                      v-model="data.contract_item.product_list[0].product_set.brand"
                      placeholder=""
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Modelo"
                    label-for="model"
                  >
                    <b-form-input
                      id="model"
                      v-model="data.contract_item.product_list[0].product_set.model"
                      placeholder=""
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                class="px-2"
              >
                <b-col>
                  <b-form-group
                    label="Descrição"
                    label-for="description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="data.contract_item.description"
                      class="itemProductDescription"
                      trim
                      :placeholder="'Descrição'"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                class="px-2"
              >
                <b-col>
                  <b-form-group
                    label="Anotações"
                    label-for="annotation"
                  >
                    <b-form-textarea
                      id="annotation"
                      v-model="data.annotation"
                      class="itemProductDescription"
                      trim
                      :placeholder="'Anotações'"
                      :readonly="readonly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-table
              ref="refItemsTable"
              class="position-relative"
              thead-class="itemTableHeader"
              primary-key="id"
              responsive
              show-empty
              striped
              empty-text="Não há itens cadastrados."
              :items="data.products_set"
              :fields="tableColumnsProduct"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center my-3">
                  <b-spinner
                    variant="info"
                    class="align-middle mb-1"
                  />
                  <br>
                  <strong class="pt-2">Carregando...</strong>
                </div>
              </template>

              <template #cell(field_supplier)="field">
                <b-media
                  v-b-tooltip.hover.bottomright
                  vertical-align="center"
                  :title="field.item.product_set.product_set.supplier_set ? field.item.product_set.product_set.supplier_set.name : ''"
                >
                  <span
                    v-if="field.item.product_set.product_set.supplier_set"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ field.item.product_set.product_set.supplier_set.name | truncate(16, '...') }}
                  </span>
                  <span
                    v-else
                    class="font-weight-bold d-block text-nowrap"
                  >
                    -
                  </span>
                </b-media>
              </template>

              <template #cell(field_item)="field">
                <b-media
                  v-b-tooltip.hover.bottomright
                  vertical-align="center"
                  :title="field.item.product_set.product_set.name"
                >
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ field.item.product_set.product_set.name | truncate(25, '...') }}
                  </span>
                </b-media>
              </template>

              <template #cell(quantity)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold text-nowrap"
                  >
                    {{ field.item.product_set.quantity }}
                  </span>
                </b-media>
              </template>

              <template #cell(field_estimated_cost)="field">
                <b-media vertical-align="center">
                  <span
                    v-b-tooltip.hover.bottomright
                    title="Valor unitário estimado para o item na licitação."
                    class="font-weight-bold d-block text-nowrap"
                  >
                    R$ {{ field.item.product_set.price | formatCurrency() }}
                  </span>
                </b-media>
              </template>

              <template #cell(field_estimated_fob)="field">
                <b-media vertical-align="center">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    R$ {{ field.item.product_set.price * (Number(data.contract_item.fob_freight) / 100) | formatCurrency() }}
                  </span>
                </b-media>
              </template>

              <template #cell(field_done_fob_freight)="field">
                <b-media vertical-align="center">
                  <cleave-customized
                    v-model="data.products_set[field.index].fob_freight"
                    :option="options.price"
                    :places="2"
                    :required-field="true"
                    :readonly="readonly"
                    @blur="calculateProfitability(field.item)"
                  />
                </b-media>
              </template>

              <template #cell(field_negotiated_value)="field">
                <b-media vertical-align="center">
                  <cleave-customized
                    v-model="data.products_set[field.index].cost"
                    v-b-tooltip.hover.bottomright
                    title="Valor unitário do item negociado com o fornecedor."
                    :option="options.price"
                    :places="2"
                    :required-field="true"
                    :readonly="readonly"
                  />
                </b-media>
              </template>

              <template #cell(field_profitability)="field">
                <b-media
                  v-if="aboveEstimatedPrice(field.index, field.item)"
                  vertical-align="center"
                >
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <svg-icon
                      v-b-tooltip.hover.bottomright
                      title="Item acima do permitido sobre o valor estimado."
                      class="d-inline-block ml-1 alertColor"
                      type="mdi"
                      :path="mdiArrowUpBoldCircle"
                    />
                  </span>
                </b-media>
              </template>
            </b-table>
          </b-form>
          <b-row
            v-if="readonly"
            class="mt-2 mb-1 pr-1"
          >
            <b-col
              class="d-flex justify-content-end"
            >
              <b-button
                id="hide-btn"
                variant="outline-primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="closeModal()"
              >
                Voltar
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
      </validation-observer>
    </div>
    <div>
      <b-modal
        id="idModalConfirmAudit"
        ref="refModalConfirmAudit"
        no-close-on-backdrop
        no-close-on-esc
        centered
        cancel-variant="outline-primary"
        @ok="save(true)"
        @cancel="cancelCommitmentAudit()"
        @hidden="cancelCommitmentAudit()"
      >

        <template #modal-title>
          Confirmar?
        </template>

        <template #modal-ok>
          Confirmar
        </template>
        <template #modal-cancel>
          Cancelar
        </template>

        <b-row
          align-h="center"
        >
          <b-col
            cols="12"
            md="12"
          >
            <div
              class="d-flex align-items-center justify-content-center"
            >
              <span
                class="font-weight-bold"
              >
                Produto com {{ auditReasonText }}, este empenho entrará em auditoria, deseja prosseguir?
              </span>
            </div>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BForm,
  BMedia,
  BModal,
  VBTooltip,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'

import {
  ValidationObserver,
} from 'vee-validate'

import {
  mdiAlphaUBoxOutline,
  mdiAlphaCBox,
  mdiMinus,
  mdiCheckboxBlankOutline,
  mdiCheckboxOutline,
  mdiNoteSearchOutline,
  mdiArrowUpBoldCircle,
  mdiArrowDownBoldCircle,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'
import CleaveCustomized from '@/components/utils/CleaveCustomized.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BForm,
    BMedia,
    BModal,
    ValidationObserver,
    SvgIcon,
    BSpinner,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    CleaveCustomized,
  },
  filters: {
    formatCurrency,
    truncate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'itemValue',
    event: 'itemValueChange',
  },
  props: {
    itemValue: {
      type: [Object, String],
      required: true,
    },
    commitment: {
      type: [Object, String],
      required: false,
      default: null,
    },
    auditPercentages: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const isLoading = true

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const sortBy = 'name'
    const isSortDirDesc = false
    const tableColumns = [
      {
        key: 'type',
        label: 'tipo',
        thStyle: { width: '5%' },
      },
      {
        key: 'name',
        label: 'Nome do produto',
        thStyle: { width: '25%' },
      },
      {
        key: 'quantity',
        label: 'QTD',
        thStyle: { width: '5%' },
      },
      {
        key: 'price',
        label: 'Valor Unitário',
        thStyle: { width: '15%' },
      },
      {
        key: 'total_cost',
        label: 'Valor Total',
        thStyle: { width: '15%' },
      },
      {
        key: 'profitability',
        label: 'Margem de Lucro Estimada (%)',
        thStyle: { width: '15%' },
      },
      {
        key: 'below_profitability',
        label: '',
        thStyle: { width: '5%' },
      },
    ]

    const tableColumnsProduct = [
      {
        key: 'field_supplier',
        label: 'Fornecedor',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_item',
        label: 'Item',
        thStyle: { width: '15%' },
      },
      {
        key: 'quantity',
        label: 'QTD',
        thStyle: { width: '5%' },
      },
      {
        key: 'field_estimated_fob',
        label: 'Frete FOB Estimado',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_done_fob_freight',
        label: 'Frete FOB Realizado',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_estimated_cost',
        label: 'Valor Estimado',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_negotiated_value',
        label: 'Valor Negociado',
        thStyle: { width: '15%' },
      },
      {
        key: 'field_profitability',
        label: '',
        thStyle: { width: '10%' },
      },
    ]

    const options = {
      price: {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
      },
    }

    const itemCommitment = []

    const itemList = {}

    const dataInitial = {}

    const cacheData = {}

    const auditReasonText = ''

    return {
      cacheData,
      refFormObserver,
      getValidationState,
      isLoading,
      sortBy,
      isSortDirDesc,
      itemCommitment,
      tableColumns,
      tableColumnsProduct,
      options,
      itemList,
      dataInitial,
      auditReasonText,
    }
  },
  computed: {
    data: {
      get() {
        return this.itemValue
      },
      set(value) {
        this.$emit('itemValueChange', value)
      },
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
  },
  methods: {
    adjustItemDecimalPlaces(index, field, places) {
      const decimalReference = 10 ** places
      const num = this.data.contract_item.product_list[index][field]

      if (num !== '') {
        const result = ((num * decimalReference) / decimalReference).toFixed(places)
        this.data.contract_item.product_list[index][field] = result
      }
      this.$refs.refItemsTable.refresh()
    },
    openUnitModal(value) {
      this.dataInitial = JSON.parse(JSON.stringify(value))
      this.isLoading = true
      this.itemCommitment.push(value)
      this.$refs.modalItemCommitment.show()
      this.isLoading = false
    },
    openCompoundModal(value) {
      this.dataInitial = JSON.parse(JSON.stringify(value))
      this.isLoading = true
      this.itemCommitment.push(value)
      this.$refs.modalItemCommitment.show()
      this.isLoading = false
    },
    save(toAudit) {
      const promiseList = []

      const requestCommitmentItem = syslic
        .order
        .commitment
        .item
        .updateCommitmentItem(this.cacheData.id, this.cacheData)

      promiseList.push(requestCommitmentItem)

      this.cacheData.products_set.forEach(element => {
        const itemProdut = JSON.parse(JSON.stringify(element))
        delete itemProdut.product_set

        const requestsItems = syslic
          .order
          .commitment
          .item
          .updateCommitmentItemProduct(itemProdut.id, itemProdut)

        promiseList.push(requestsItems)
      })

      Promise
        .all(promiseList)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Item atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          if (toAudit) {
            this.$emit('updateAudit', 'audit')
          } else {
            this.$emit('update')
          }
          if (this.commitment.situation === 'declined') {
            this.$emit('updateSituation')
          }
        })
        .catch(error => {
          let textError = 'Não foi possível atualizar o item do empenho do servidor, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('this order is linked to a delivery, for this reason it cannot be changed.')) {
              textError = 'Este pedido está vinculado a uma entrega, por isso não pode ser atualizado.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar item.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    cancel() {
      this.itemCommitment = []
      this.data = this.dataInitial
      this.$emit('update')
      this.closeModal()
    },
    addItem(obj) {
      const qtd = Number(this.itemList[obj.item].quantity)

      if (qtd < obj.quantity) {
        this.itemList[obj.item].quantity = qtd + 1
      }

      this.$refs.refItemTable.refresh()
    },
    removeItem(obj) {
      const qtd = Number(this.itemList[obj.item].quantity)

      if (qtd > 0) {
        this.itemList[obj.item].quantity = qtd - 1
      }

      this.$refs.refItemTable.refresh()
    },
    closeModal() {
      this.$refs.modalItemCommitment.hide()
    },
    hasAllNegotiatedValue() {
      let hasNegotiatedValue = true

      hasNegotiatedValue = this.data.products_set.every(element => !(element.cost === '' || element.cost === '0.00' || element.cost === 0 || element.fob_freight === ''))

      return hasNegotiatedValue
    },
    calculateProfitability(product) {
      let costUnitary = 0
      let freightFob = 0

      if (product.products_set.length > 0) {
        product.products_set.forEach(element => {
          costUnitary += (Number(element.cost) * Number(element.product_set.quantity))
          freightFob += Number(element.fob_freight)
        })
      }

      const contractItemPrice = Number(product.contract_item.price)
      const contractItemTax = (Number(product.contract_item.tax) / 100)
      const contractItemFreightPercentage = (Number(product.contract_item.freight) / 100)
      const contractItemFixedCostPercentage = (Number(product.contract_item.fixed_cost) / 100)

      const fixedCost = (contractItemPrice * contractItemFixedCostPercentage)
      const freight = (contractItemPrice * contractItemFreightPercentage)
      const tax = (contractItemPrice * contractItemTax)

      const total = (contractItemPrice - (fixedCost + freight + tax + freightFob + costUnitary))
      const margin = ((total / contractItemPrice) * 100)

      return margin
    },
    isAboveEstimated(product) {
      let isAbove = false

      product.products_set.forEach(item => {
        const currentValue = parseFloat(item.product_set.price)
        const additional = parseFloat(item.product_set.price * (this.auditPercentages.item / 100))
        const maxValue = (currentValue + additional).toFixed(2)

        const exceedValueWithValidLastAudit = (
          Number(item.last_audit_value) > 0
          && Number(item.cost) !== Number(item.last_audit_value)
          && Number(item.cost) > maxValue
        )
        const exceedValueWithoutLastAudit = (
          Number(item.last_audit_value) === 0 && Number(item.cost) > maxValue
        )

        if (exceedValueWithValidLastAudit || exceedValueWithoutLastAudit) {
          isAbove = true
        }
      })

      return isAbove
    },
    isBelowProfitability(product) {
      const margin = Number(parseFloat(this.calculateProfitability(product).toFixed(2)))
      const auditMargin = Number(parseFloat(this.auditPercentages.margin).toFixed(2))

      return (margin < auditMargin)
    },
    isBelowProfitabilityAndAuditable(product) {
      const margin = Number(parseFloat(this.calculateProfitability(product).toFixed(2)))
      const auditMargin = Number(parseFloat(this.auditPercentages.margin).toFixed(2))
      const lastAuditMargin = Number(parseFloat((product.last_audit_margin)).toFixed(2))

      return (margin < auditMargin) && (margin !== lastAuditMargin)
    },
    handleAuditText(abovePrice, aboveMargin) {
      if (abovePrice && aboveMargin) {
        this.auditReasonText = 'valor acima do permitido e margem de lucro abaixo do permitido'
      } else if (abovePrice) {
        this.auditReasonText = 'valor acima do permitido'
      } else if (aboveMargin) {
        this.auditReasonText = 'margem de lucro abaixo do permitido'
      }
    },
    checkAuditPercentages() {
      this.cacheData = JSON.parse(JSON.stringify(this.data))
      const commitmentItem = JSON.parse(JSON.stringify(this.data))

      const belowMinimumMargin = this.isBelowProfitabilityAndAuditable(commitmentItem)
      const aboveEstimatedPrice = this.isAboveEstimated(commitmentItem)

      if (aboveEstimatedPrice || belowMinimumMargin) {
        this.handleAuditText(aboveEstimatedPrice, belowMinimumMargin)
        this.$refs.refModalConfirmAudit.show()
      } else {
        this.save()
      }
    },
    cancelCommitmentAudit() {
      this.$refs.refModalConfirmAudit.hide()
    },
    aboveEstimatedPrice(index, item) {
      return (((this.data.products_set[index].cost / item.product_set.price) - 1) * 100) > this.auditPercentages.item
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiAlphaUBoxOutline,
      mdiAlphaCBox,
      mdiMinus,
      mdiCheckboxBlankOutline,
      mdiCheckboxOutline,
      mdiNoteSearchOutline,
      mdiArrowUpBoldCircle,
      mdiArrowDownBoldCircle,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idModalItemCommitment {
  .modal-body{
    padding: 0 !important;
  }
  .modal-header .modal-title{
    font-size: 1.2em;
    color: $primary !important;
    font-weight: bolder;
  }
}

#idModalItemCommitment .modal-footer {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: end !important;
  background-color: #fff !important;

  .btn{
    min-width: 107px;
  }
}

[dir] .modal-footer {
  border: 0px !important;
  background-color: #fff !important;
}

.inconType {
  color: $primary;
}

#idModalItemCommitment .itemTableHeader th {
  color: $primary;
  background-color: #E3EDF3;
}

.itemProductDescription {
  min-height: 8.25rem;
}

#idModalConfirmAudit {
  .modal-body {
    padding: 3.5rem 2.5rem 1rem 2.5rem;
    span {
      text-align: center;
      font-size: 1.35rem;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    padding-bottom: 2.5rem;

    border-top: none !important;

    button {
      min-width: 8rem;
    }
  }
}

.alertColor {
  color: #F50F00;
}
</style>
